<template>
    <div id="register">
        <form v-if="$route.params.target === 'user' || $route.params.target === 'partner'"
              class="md-layout md-alignment-top-center" novalidate
              @submit.prevent="validateUser" id="registerForm">
            <md-card class="md-layout-item md-large-size-70 md-small-size-90">
                <md-card-header>
                    <div class="md-title">
                        Neuen
                        <span v-if="$route.params.target === 'user'">
                            Kunden
                        </span>
                        <span v-else-if="$route.params.target === 'partner'">
                            Partner
                        </span>
                        anlegen
                    </div>
                </md-card-header>

                <md-card-content>
                    <fieldset>
                        <legend>Zugangsdaten</legend>
                        <div class="md-layout md-gutter">
                            <div class="md-layout-item md-small-size-100">
                                <md-field v-if="$route.params.target === 'user' || $route.params.target === 'partner'"
                                          :class="getValidationClass('username')">
                                    <label for="email">Email</label>
                                    <md-input id="email" v-model="form.username" :disabled="sending" autocomplete="email"
                                              name="email"
                                              type="email"/>
                                    <span v-if="!$v.form.username.required"
                                          class="md-error">
                                        Es muss eine Mailadresse angegeben werden!
                                    </span>
                                    <span v-else-if="!$v.form.username.email" class="md-error">
                                        Diese Mailadresse ist ungültig!
                                    </span>
                                </md-field>
                            </div>
                        </div>
                        <div class="md-layout md-gutter">
                            <div class="md-layout-item md-small-size-100">
                                <md-field :class="getValidationClass('password')">
                                    <label for="password">Passwort</label>
                                    <md-input id="password" v-model="form.password" :disabled="sending"
                                              autocomplete="new-password"
                                              name="password" type="password"/>
                                    <span v-if="!$v.form.password.required" class="md-error">
                                        Es muss ein Passwort angegeben werden!
                                    </span>
                                    <span v-else-if="!$v.form.password.minLength" class="md-error">
                                        Zu kurzes Passwort!
                                    </span>
                                    <span v-else-if="!$v.form.password.passwordContainsDigit" class="md-error">
                                        Es fehlt eine Zahl!
                                    </span>
                                    <!--<span v-else-if="!$v.form.password.passwordContainsUpper" class="md-error">
                                        Es fehlt eine Großbuchstabe!
                                    </span>-->
                                    <span v-else-if="!$v.form.password.passwordContainsLower" class="md-error">
                                        Es fehlt eine Kleinbuchstabe!
                                    </span>
                                    <span v-else-if="!$v.form.password.passwordContainsSpecial" class="md-error">
                                        Es fehlt ein Sonderzeichen!
                                    </span>
                                    <span v-else-if="!$v.form.password.passwordOnlyValidChars" class="md-error">
                                        Ungültiges Zeichen!
                                    </span>
                                </md-field>
                            </div>

                            <div class="md-layout-item md-small-size-100">
                                <md-field :class="getValidationClass('confirmPassword')">
                                    <label for="confirmPassword">Passwort wiederholen</label>
                                    <md-input id="confirmPassword" v-model="form.confirmPassword" :disabled="sending"
                                              autocomplete="new-password" name="confirmPassword" type="password"/>
                                    <span v-if="!$v.form.confirmPassword.required" class="md-error">
                                        Das Passwort muss hier wiederholt werden!
                                    </span>
                                    <span v-else-if="!$v.form.confirmPassword.sameAs" class="md-error">
                                        Die beiden Passwörter müssen übereinstimmen!
                                    </span>
                                </md-field>
                            </div>

                            <div class="md-layout-item md-size-100">
                                Das Passwort muss einen Groß- und einen Kleinbuchstaben, sowie eine Zahl und ein Sonderzeichen
                                enthalten. Es muss mindestens 6 Zeichen lang sein.
                                <br>
                                <div v-if="$v.$invalid && $v.form.password.$dirty && !$v.form.password.passwordOnlyValidChars"
                                     :class="getValidationClass('password')">
                                    <span class="md-error">
                                        Erlaubte Sonderzeichen: <code>{{ specials }}</code>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset>
                        <legend>Persönliche Daten</legend>
                        <div class="md-layout md-gutter">
                            <div class="md-layout-item md-small-size-100">
                                <md-field :class="getValidationClass('firstName')">
                                    <label for="first-name">Vorname</label>
                                    <md-input id="first-name" v-model="form.firstName" :disabled="sending"
                                              autocomplete="given-name"
                                              name="first-name"/>
                                    <span v-if="!$v.form.firstName.required" class="md-error">
                                        Es muss ein Vorname angegeben werden!
                                    </span>
                                    <span v-else-if="!$v.form.firstName.minLength" class="md-error">
                                        Der Vorname ist zu kurz!
                                    </span>
                                </md-field>
                            </div>

                            <div class="md-layout-item md-small-size-100">
                                <md-field :class="getValidationClass('lastName')">
                                    <label for="last-name">Nachname</label>
                                    <md-input id="last-name" v-model="form.lastName" :disabled="sending"
                                              autocomplete="family-name"
                                              name="last-name"/>
                                    <span v-if="!$v.form.lastName.required"
                                          class="md-error">
                                        Es muss ein Nachname angegeben werden!
                                    </span>
                                    <span v-else-if="!$v.form.lastName.minLength" class="md-error">
                                        Der Nachname ist zu kurz!
                                    </span>
                                </md-field>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset>
                        <legend>Adresse</legend>
                        <div class="md-layout md-gutter">
                            <div class="md-layout-item md-small-size-100">
                                <md-field :class="getValidationClass('street')">
                                    <label for="street">Straße & Hausnummer</label>
                                    <md-input id="street" v-model="form.street" :disabled="sending"
                                              autocomplete="shipping street-address"
                                              name="street"/>
                                    <span v-if="!$v.form.street.required"
                                          class="md-error">
                                        Es muss eine Straße und eine Hausnummer angegeben werden!
                                    </span>
                                    <span v-else-if="!$v.form.street.minLength" class="md-error">
                                        Ungültige Straße (mit Hausnummer)!
                                    </span>
                                </md-field>
                            </div>
                        </div>
                        <div class="md-layout md-gutter">
                            <div class="md-layout-item md-small-size-100">
                                <md-field :class="getValidationClass('zipCode')">
                                    <label for="zipCode">PLZ</label>
                                    <md-input id="zipCode" v-model="form.zipCode" :disabled="sending"
                                              autocomplete="shipping postal-code"
                                              name="zipCode"/>
                                    <span v-if="!$v.form.zipCode.required" class="md-error">
                                        Es muss eine PLZ angegeben werden!
                                    </span>
                                    <span v-else-if="!$v.form.zipCode.minLength" class="md-error">
                                        Ungültige PLZ
                                    </span>
                                </md-field>
                            </div>

                            <div class="md-layout-item md-small-size-100">
                                <md-field :class="getValidationClass('city')">
                                    <label for="city">Stadt</label>
                                    <md-input id="city" v-model="form.city" :disabled="sending" autocomplete="address-level2"
                                              name="city"/>
                                    <span v-if="!$v.form.city.required" class="md-error">
                                        Es muss eine Stadt angegeben werden!
                                    </span>
                                    <span v-else-if="!$v.form.city.minLength" class="md-error">
                                        Ungültige Stadt
                                    </span>
                                </md-field>
                            </div>

                            <div class="md-layout-item md-small-size-100">
                                <md-field :class="getValidationClass('country')">
                                    <label for="country">Land</label>
                                    <md-select v-model="form.country" :disabled="sending" name="country" id="country">
                                        <md-option v-for="c in countries" v-bind:key="c.id" :value="c.id">{{ c.label }}
                                        </md-option>
                                    </md-select>
                                    <!--
                                    <span v-if="!$v.form.country.required" class="md-error">
                                        Es muss eine Stadt angegeben werden!
                                    </span>
                                    -->
                                </md-field>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset v-if="$route.params.target === 'partner'">
                        <legend>Unternehmensdaten</legend>
                        <div class="md-layout md-gutter">
                            <div class="md-layout-item md-small-size-100">
                                <md-field :class="getValidationClass('companyName')">
                                    <label for="organization">Firmenname</label>
                                    <md-input id="organization" v-model="form.companyName" :disabled="sending"
                                              autocomplete="organization" name="organization"/>
                                    <span v-if="!$v.form.companyName.required"
                                          class="md-error">
                                        Es muss ein Firmenname angegeben werden!
                                    </span>
                                    <span v-else-if="!$v.form.companyName.minLength"
                                          class="md-error">
                                        Der Firmenname ist zu kurz!
                                    </span>
                                </md-field>
                            </div>
                        </div>
                        <div class="md-layout md-gutter">
                            <div class="md-layout-item md-small-size-100">
                                <md-field :class="getValidationClass('phoneNumber')">
                                    <label for="phoneNumber">Telefonnummer</label>
                                    <md-input id="phoneNumber" v-model="form.phoneNumber" :disabled="sending" autocomplete="tel"
                                              name="phoneNumber"/>
                                    <span v-if="!$v.form.phoneNumber.required" class="md-error">
                                        Es muss eine Telefonnummer angegeben werden!
                                    </span>
                                    <span v-else-if="!$v.form.phoneNumber.minLength" class="md-error">
                                        Telefonnummer ungültig
                                    </span>
                                </md-field>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset v-if="$route.params.target === 'partner'">
                        <legend>Präqualifikation</legend>
                        <div class="md-layout md-gutter">
                            <div class="md-layout-item md-size-50 md-small-size-100">
                                <md-field :class="getValidationClass('ik')">
                                    <label for="ik">Institutionskennzeichen (IK)</label>
                                    <md-input id="ik" v-model="form.ik" :disabled="sending" autocomplete="off" name="ik"/>
                                    <span v-if="!$v.form.ik.required" class="md-error">
                                        Es muss ein IK angegeben werden!
                                    </span>
                                    <span v-else-if="!$v.form.ik.ik_valid" class="md-error">
                                        Das IK ist nicht gültig!
                                    </span>
                                </md-field>
                            </div>
                            <div class="md-layout-item md-size-50 md-small-size-100">
                                <md-field :class="getValidationClass('regNumber')">
                                    <label for="regNumber">Registrier-Nr. PQ</label>
                                    <md-input id="regNumber" v-model="form.regNumber" :disabled="sending" autocomplete="off"
                                              name="regNumber"/>
                                    <span v-if="!$v.form.regNumber.required" class="md-error">
                                        Es muss eine Registrier-Nr. angegeben werden!
                                    </span>
                                    <span v-else-if="!$v.form.regNumber.minLength" class="md-error">
                                        Registrier-Nr. ungültig
                                    </span>
                                </md-field>
                            </div>
                        </div>
                        <div class="md-layout md-gutter">
                            <div class="md-layout-item md-size-50">
                                <md-field :class="getValidationClass('validSince')">
                                    <label>PQ gültig ab</label>
                                    <md-input v-model="form.validSince" type="date" :disabled="sending"
                                              autocomplete="off"/>
                                    <span v-if="!$v.form.validSince.required" class="md-error">
                                        Es muss ein Gültigkeitsstart angegeben werden!
                                    </span>
                                </md-field>
                            </div>
                            <div class="md-layout-item md-size-50">
                                <md-field :class="getValidationClass('validUntil')">
                                    <label>PQ gültig bis</label>
                                    <md-input v-model="form.validUntil" type="date" :disabled="sending"
                                              autocomplete="off"/>
                                    <span v-if="!$v.form.validUntil.required" class="md-error">
                                        Es muss ein Gültigkeitsende angegeben werden!
                                    </span>
                                </md-field>
                            </div>
                        </div>
                        <div class="md-layout md-gutter">
                            <div class="md-layout-item md-size-100">
                                <span>Versorgungsbereiche</span>
                            </div>
                            <div class="md-layout-item md-small-size-50" style="text-align: left">
                                <md-checkbox id="t_05A5" v-model="form.treatments.t_05A5" :disabled="sending" autocomplete="off"
                                             name="t_05A5">05A5
                                </md-checkbox>
                            </div>
                            <div class="md-layout-item md-small-size-50" style="text-align: left">
                                <md-checkbox id="t_08A" v-model="form.treatments.t_08A" :disabled="sending" autocomplete="off"
                                             name="t_08A">08A
                                </md-checkbox>
                            </div>
                            <div class="md-layout-item md-small-size-50" style="text-align: left">
                                <md-checkbox id="t_08B10" v-model="form.treatments.t_08B10" :disabled="sending" autocomplete="off"
                                             name="t_08B10">08B10
                                </md-checkbox>
                            </div>
                            <div class="md-layout-item md-small-size-50" style="text-align: left">
                                <md-checkbox id="t_17A4" v-model="form.treatments.t_17A4" :disabled="sending" autocomplete="off"
                                             name="t_17A4">17A4
                                </md-checkbox>
                            </div>
                            <div class="md-layout-item md-small-size-50" style="text-align: left">
                                <md-checkbox id="t_20C" v-model="form.treatments.t_20C" :disabled="sending" autocomplete="off"
                                             name="t_20C">20C
                                </md-checkbox>
                            </div>
                            <div class="md-layout-item md-small-size-50" style="text-align: left">
                                <md-checkbox id="t_20G10" v-model="form.treatments.t_20G10" :disabled="sending" autocomplete="off"
                                             name="t_20G10">20G10
                                </md-checkbox>
                            </div>
                            <div class="md-layout-item md-small-size-50" style="text-align: left">
                                <md-checkbox id="t_23A3" v-model="form.treatments.t_23A3" :disabled="sending" autocomplete="off"
                                             name="t_23A3">23A3
                                </md-checkbox>
                            </div>
                            <div class="md-layout-item md-small-size-50" style="text-align: left">
                                <md-checkbox id="t_23B3" v-model="form.treatments.t_23B3" :disabled="sending" autocomplete="off"
                                             name="t_23B3">23B3
                                </md-checkbox>
                            </div>
                            <div class="md-layout-item md-small-size-50" style="text-align: left">
                                <md-checkbox id="t_23C10" v-model="form.treatments.t_23C10" :disabled="sending" autocomplete="off"
                                             name="t_23C10">23C10
                                </md-checkbox>
                            </div>
                            <div class="md-layout-item md-small-size-50" style="text-align: left">
                                <md-checkbox id="t_23E" v-model="form.treatments.t_23E" :disabled="sending" autocomplete="off"
                                             name="t_23E">23E
                                </md-checkbox>
                            </div>
                            <div class="md-layout-item md-small-size-50" style="text-align: left">
                                <md-checkbox id="t_31A" v-model="form.treatments.t_31A" :disabled="sending" autocomplete="off"
                                             name="t_31A">31A
                                </md-checkbox>
                            </div>
                            <div class="md-layout-item md-small-size-50" style="text-align: left">
                                <md-checkbox id="t_31C" v-model="form.treatments.t_31C" :disabled="sending" autocomplete="off"
                                             name="t_31C">31C
                                </md-checkbox>
                            </div>
                            <div class="md-layout-item md-small-size-50" style="text-align: left">
                                <md-checkbox id="t_31D" v-model="form.treatments.t_31D" :disabled="sending" autocomplete="off"
                                             name="t_31D">31D
                                </md-checkbox>
                            </div>
                            <div class="md-layout-item md-small-size-50" style="text-align: left">
                                <md-checkbox id="t_31E" v-model="form.treatments.t_31E" :disabled="sending" autocomplete="off"
                                             name="t_31E">31E
                                </md-checkbox>
                            </div>
                        </div>
                        <div class="md-layout md-gutter">
                            <div class="md-layout-item md-size-100">
                                <md-field>
                                    <label for="t_other">Weitere Versorgungsbereiche (mit Komma trennen)</label>
                                    <md-input id="t_other" v-model="form.treatments.t_other" :disabled="sending"
                                              autocomplete="off"
                                              name="t_other"/>
                                    <!-- TODO Daten prüfen -->
                                </md-field>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset>
                        <legend>Datenschutz & AGB</legend>
                        <div class="md-layout md-gutter">
                            <table class="md-layout-item md-size-100" style="text-align: left">
                                <tr>
                                    <td class="checkbox-col">
                                        <md-checkbox id="privacy" v-model="form.privacy" :class="getValidationClass('privacy')"
                                                     :disabled="sending" autocomplete="off" name="privacy">
                                        </md-checkbox>
                                    </td>
                                    <td>
                                        <label for="privacy">
                                            Ich bin mit der
                                            <a href="/#/privacypolicy/" target="_blank">
                                                Datenschutzerklärung
                                            </a>
                                            einverstanden.
                                        </label>
                                        <span v-if="$v.form.privacy.$error" class="md-error">
                                            <br>Bitte stimme der Datenschutzerklärung zu.
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="checkbox-col">
                                        <md-checkbox id="terms" v-model="form.terms" :class="getValidationClass('terms')"
                                                     :disabled="sending" autocomplete="off" name="terms">
                                        </md-checkbox>
                                    </td>
                                    <td>
                                        <label for="terms">
                                            Ich bin mit der
                                            <a href="/#/terms/" target="_blank">
                                                Allgemeinen Nutzungsbedingungen
                                            </a>
                                            einverstanden.
                                        </label>
                                        <span v-if="$v.form.terms.$error" class="md-error">
                                            <br>Bitte stimme den AGB zu.
                                        </span>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </fieldset>

                    <fieldset v-if="$route.params.target === 'partner'">
                        <legend>Partner-Newsletter</legend>
                        <div class="md-layout md-gutter">
                            <table class="md-layout-item md-size-100" style="text-align: left">
                                <tr>
                                    <td class="checkbox-col">
                                        <md-checkbox id="newsletter" v-model="form.newsletter" :class="getValidationClass('newsletter')"
                                                     :disabled="sending" autocomplete="off" name="newsletter">
                                        </md-checkbox>
                                    </td>
                                    <td>
                                        <label for="newsletter">
                                            Ich möchte gerne Newsletter (ca. 2 mal pro Jahr) bekommen.
                                        </label>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </fieldset>
                </md-card-content>

                <md-progress-bar v-if="sending" md-mode="indeterminate"/>

                <md-card-actions>
                    <md-button v-if="$route.params.target === 'partner'" class="md-primary" to="/register/user">
                        Als Nutzer
                    </md-button>
                    <md-button v-if="$route.params.target === 'user'" class="md-primary" to="/register/partner">
                        Als Partner
                    </md-button>
                    <div class="spacer"></div>
                    <md-button :disabled="sending" class="md-primary" v-on:click="clearForm()">Löschen</md-button>
                    <md-button :disabled="sending" class="md-primary md-raised" type="submit">Registrieren</md-button>
                </md-card-actions>
            </md-card>
        </form>

        <div class="md-layout md-alignment-top-center">
            <md-card v-if="!$route.params.target || !($route.params.target === 'user'
                     || $route.params.target === 'partner' || $route.params.target === 'success')"
                     class="md-layout-item md-size-50 md-small-size-100">
                <md-card-header>
                    <div class="md-title">
                        Registierung...
                    </div>
                </md-card-header>
                <md-card-actions>
                    <md-button class="md-primary" to="/register/user">... als Nutzer</md-button>
                    <md-button class="md-primary" to="/register/partner">... als PAW-Partner</md-button>
                </md-card-actions>
            </md-card>


            <md-card v-if="$route.params.target && $route.params.target === 'success' && type !== ''"
                     class="md-layout-item md-size-50 md-small-size-100">
                <md-card-header>
                    <div class="md-title">
                        Die Registierung war erfolgreich
                    </div>
                </md-card-header>
                <md-card-content v-if="type === 'user'">
                    Vielen Dank für Deine Registrierung als Nutzer!
                    <br><br>
                    <h3>Nächste Schritte:</h3>
                    <ol>
                        <li>Aktiviere dein Nutzerkonto mit dem Link, den wir dir eben per Mail zugeschickt haben.</li>
                        <li>Melde dich an und probiere alles aus.</li>
                    </ol>
                </md-card-content>
                <md-card-content v-if="type === 'partner'">
                    Danke, dass Sie sich als Partner bei paw-blueprint.de registriert haben.
                    Nach Überprüfung Ihrer Daten werden Sie im System freigeschalten.
                    Sie erhalten hierzu eine Email-Benachrichtigung.
                    <br><br>
                    <h3>Nächste Schritte:</h3>
                    <ol>
                        <li>Aktiviere dein Nutzerkonto mit dem Link, den wir dir eben per Mail zugeschickt haben.</li>
                        <li>Anschließend muss ein Administrator deine Daten prüfen und das Konto aktivieren.</li>
                        <li>Melde dich an und probiere alles aus.</li>
                    </ol>
                </md-card-content>
            </md-card>
        </div>
    </div>
</template>

<script>
import {email, minLength, required, sameAs} from 'vuelidate/lib/validators'
import {
    passwordContainsDigit,
    passwordContainsLower,
    passwordContainsSpecial,
    passwordOnlyValidChars,
    specials
} from '@/helpers/passwordConstraints'
import mobileCheck from "@/helpers/mobileCheck";

const countryCodes = require('country-codes-list')

const relevantCountryCodes = ['DE']


/**
 * Validation of Institutionskennung
 * @param value
 * @returns {boolean}
 */
const ik_valid = (value) => {
    let val = "" + value;
    val = val.replace(/ /g, "")
    val = val.replace(/IK/g, "")
    val = val.replace(/[a-z]/g, "")
    val = val.replace(/[A-Z]/g, "")
    val = val.substr(2, 7)
    let sum = 0
    for (let i = 0; i < 6; i++) {
        let f1 = (i % 2 === 0) ? 2 : 1;
        let f2 = f1 * parseInt(val[i])
        let f3 = 0
        if (f2 >= 10) {
            f3 = f2 - 10 + 1;
        } else {
            f3 = f2;
        }
        sum += f3
    }
    sum = sum % 10;
    return sum === parseInt(val[6]);
}

export default {
    name: 'All_Registration',
    computed: {
        hint() {
            return "" + this.form.agb + " " + this.$v.form.agb.$invalid + " " + this.$v.form.agb.$error
        },
        specials() {
            return specials
        },
        countries() {
            let label = "{countryNameLocal}"
            if (mobileCheck()) { // mobile device --> show Flags
                label += " {flag}"
            }
            const codes = countryCodes.customList('countryCode', label)
            let r = []
            for (let elem of relevantCountryCodes) {
                r.push({id: elem, label: codes[elem]})
            }
            return r
        }
    },
    watch: {},
    mounted() {
    },
    data: () => ({
        form: {
            // Personendaten
            username: '',
            password: '',
            confirmPassword: '',
            firstName: '',
            lastName: '',
            birthDay: new Date(),
            isCompany: '',
            //Address
            street: '',
            zipCode: '',
            city: '',
            country: 'DE',
            // Firmendaten
            companyName: '',
            phoneNumber: '',
            // Präqualifikation
            ik: '',
            validSince: new Date(),
            validUntil: new Date(),
            regNumber: '',
            treatments: {
                t_05A5: false,
                t_08A: false,
                t_08B10: false,
                t_17A4: false,
                t_20C: false,
                t_20G10: false,
                t_23A3: false,
                t_23B3: false,
                t_23C10: false,
                t_23E: false,
                t_31A: false,
                t_31C: false,
                t_31D: false,
                t_31E: false,
                t_other: '',
            },
            // Datenschutz & AGB
            privacy: false,
            terms: false,
            newsletterAccepted: false,
        },
        userSaved: false,
        sending: false,
        type: null,
    }),
    validations() {
        /* TODO check ob älter als 18 Jahre */
        let form = {
            username: {
                required,
                email
            },
            firstName: {
                required,
                minLength: minLength(2)
            },
            lastName: {
                required,
                minLength: minLength(2)
            },
            password: {
                required,
                minLength: minLength(6),
                passwordContainsDigit: passwordContainsDigit,
                //passwordContainsUpper: passwordContainsUpper,
                passwordContainsLower: passwordContainsLower,
                passwordContainsSpecial: passwordContainsSpecial,
                passwordOnlyValidChars: passwordOnlyValidChars,
            },
            confirmPassword: {
                required,
                sameAs: sameAs('password')
            },
            street: {
                required
            },
            zipCode: {
                required
            },
            city: {
                required
            },
            terms: {
                sameAs: sameAs(() => true)
            },
            privacy: {
                sameAs: sameAs(() => true)
            },
        }
        if (this.isPartner()) {
            // Adding partner-specific attributes
            form = {
                ...form,
                ...{
                    companyName: {
                        required
                    },
                    phoneNumber: {
                        required
                    },
                    ik: {
                        required,
                        ik_valid
                    },
                    validSince: {
                        required
                    },
                    validUntil: {
                        required
                    },
                    regNumber: {
                        required
                    },
                    newsletterAccepted: {
                        // no rules apply
                    }
                }
            }
        }

        return {form}
    },
    methods: {
        isPartner() {
            return this.$route.params.target === 'partner'
        },
        getValidationClass(fieldName) {
            const field = this.$v.form[fieldName];

            if (field) {
                return {
                    'md-invalid': field.$invalid && field.$dirty
                }
            }
        },
        clearForm() {
            this.$v.$reset();
            document.getElementById("registerForm").reset();
            for (let t in this.form.treatments) {
                if (t !== "t_other")
                    this.form.treatments[t] = false;
            }
            this.form.privacy = false;
        },
        validateUser() {
            this.$v.form.$touch();

            if (!this.$v.$invalid) {
                let form = this.form
                let req = {
                    email: form.username,
                    password: form.password,
                    confirmPassword: form.confirmPassword,
                    firstName: form.firstName,
                    lastName: form.lastName,
                    isCompany: this.isPartner(),
                    address: {
                        street: form.street,
                        postCode: form.zipCode,
                        city: form.city,
                        country: form.country
                    },
                    termsAccepted: form.privacy && form.terms,
                    newsletterAccepted: form.newsletterAccepted
                }


                if (req.isCompany) {
                    let serviceAreas = ""
                    for (let a in form.treatments) {
                        if (a === 't_other') {
                            continue
                        }
                        if (form.treatments[a]) {
                            serviceAreas = serviceAreas + a.substring(2) + ", "
                        }
                    }
                    if (form.treatments['t_other'].length > 0) {
                        serviceAreas = serviceAreas + form.treatments['t_other']
                    } else {
                        serviceAreas = serviceAreas.substring(0, serviceAreas.length - 2)
                    }

                    req.company = {
                        name: form.companyName,
                        phoneNumber: form.phoneNumber,
                        ik: form.ik,
                        craftsmenRegister: form.regNumber,
                        pq_since: form.validSince,
                        pq_until: form.validUntil,
                        serviceAreas: serviceAreas,
                    }
                } else {
                    req.company = null
                }

                this.$store.dispatch("createUser", req).then(() => {
                    this.$v.$reset();
                    this.type = '' + this.$route.params.target;
                    this.$router.replace("/register/success")
                })
            } else {
                console.log("Vuelidation failed!")
            }
        },
    }
}
</script>

<style lang="scss" scoped>
#register {
    margin: auto;
}

.spacer {
    flex-grow: 1;
}

.md-error {
    color: var(--md-theme-default-fieldvariant, #ff1744);
    font-size: 12px;
}

.checkbox-col {
    width: 30px;
}
</style>
